import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React, { useState ,useEffect} from 'react';
import useBackend from '../services/useBackend';
import BackendFunction from '../services/BackendFunction';
import {useParams, useNavigate} from 'react-router-dom';
import LockInfo from './LockInfo';

export default function MyComponent() {

    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText]       = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);
    const [enableKlipp, setEnableKlipp]     = useState(false);
    const [keyTypeId, setKeyTypeId]         = useState("0");

    const navigate = useNavigate();    

    const {LockGUID} = useParams();

    const {loading, lockData} =  useBackend('/api/v1/Lock/'+ LockGUID );  

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );  

    const handleIssueKeyClick = async (e) =>{
        e.preventDefault();

        setResultText("Skapar nyckel.....");
        setResultVariant("warning");
        setResultVisible(true);                

        var data            = { LockGuid : LockGUID, NumberOfUse:0, Email_Send:true };
        data.Email          = e.target.elements.Email.value;
        data.Email_TextHTML = e.target.elements.TextHTMLToMail.value;
        data.Email_Subject  = 'Digital nyckel'; 
        data.TimeFrameStart = e.target.elements.TimeFrameStart.value;
        data.TimeFrameEnd   = e.target.elements.TimeFrameEnd.value;
        data.keyTypeId      = keyTypeId;                    
        data.numOfPunches   = e.target.elements.numOfPunches.value;                    

        var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Key/Issue','POST', data);

        await delay(1000);
        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(resultVisible);
    }

    const changeKeyTypeHandler = async (e) => {
        var keyTypeId = e.target.value;
        console.log(keyTypeId);
        
        if (keyTypeId=="0") setEnableKlipp(false) ;
        if (keyTypeId=="1") setEnableKlipp(false) ;
        if (keyTypeId=="2") setEnableKlipp(true) ;
        if (keyTypeId=="3") setEnableKlipp(true) ;
        if (keyTypeId=="4") setEnableKlipp(true) ;

        setKeyTypeId(keyTypeId);
    }

    const today = new Date();
    // const numberOfDaysToAdd = 3;
    const date = today.setDate(today.getDate()); 
    const datePlus30 = today.setDate(today.getDate() +30); 
    const defaultTimeFrameStart = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    const defaultTimeFrameEnd = new Date(datePlus30).toISOString().split('T')[0] // yyyy-mm-dd

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>


                    <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Dela nyckel</Rubrik>
                        <LockInfo LockGUID={LockGUID}></LockInfo>

                        <Form onSubmit={handleIssueKeyClick}>

                            <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" name ="Email" placeholder="hen@company.se"  />
                            </Form.Group>
                            
                            <Form.Group className="mb-3" >
                                    <Form.Label>Beskrivning</Form.Label>
                                    <Form.Control type="text" name="TextHTMLToMail" placeholder="" defaultValue="Här kommer din digitala nyckel till xxx" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                    <Form.Label>Nyckeln gäller från</Form.Label>
                                    <Form.Control type="date" name="TimeFrameStart"  defaultValue={defaultTimeFrameStart}/>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                    <Form.Label>Nyckeln gäller till</Form.Label>
                                    <Form.Control type="date" name="TimeFrameEnd"  defaultValue={defaultTimeFrameEnd}/>
                            </Form.Group>

                            <Form.Group className="mb-3"  controlId="formBasicEmail11">
                                <Form.Label>Typ av nyckel</Form.Label>
                                <Form.Control as="select"  name="keyTypeId" value={keyTypeId} onChange={changeKeyTypeHandler}>
                                    <option value="0">Välj Nyckeltyp</option>
                                    <option value="1">Tidsbegränsad datumperiod</option>
                                    <option value="2">Klippkort varje passage/klipp</option>
                                    <option value="3">Klippkort innevarande dag/klipp</option>
                                    <option value="4">Klippkort 24h/klipp</option>                                    
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                    <Form.Label>Antal klipp</Form.Label>
                                    <Form.Control type="text" name="numOfPunches" placeholder="" defaultValue="0" disabled={enableKlipp == false ? 'disabled' : ''}/>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Check 
                                    disabled
                                    type="switch"
                                    label="Kräv BankID"
                                    name="BankID"
                                    checked_
                                    value=""
                                />
                            </Form.Group>                                                  

                            <Form.Group className="mb-3"  hidden={!loading}>
                                <SpinnerX  />
                            </Form.Group>                                      
                                
                            <Form.Group className="mb-3"  hidden={!resultVisible}>
                                <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                            </Form.Group>    

                            <Form.Group className="mb-3">
                                <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit">Skapa nyckel</Button>
                            </Form.Group>                                                                              
                        </Form>  
                    </Sida>                    
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar information om låset...</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}