import React, { useState ,useEffect, useRef } from 'react';


export default function useGetBackend(url) {
    const [data, setData] = useState([]);
    const [loading, setLoadning] = useState(true);

    const [resultVariant, setResultVariant] = useState(null);
    const [resultText,    setResultText]    = useState(null);
    const [resultVisible, setResultVisible] = useState(null);

    var queryParam = useRef("");

    function Refresh() {
        init();
    }

    function Seek(QueryParam) {
        queryParam.current = QueryParam;
        init();
    }    

    async function init() {
        //setLoadning(true);
        try {
            const response = await fetch(url + queryParam.current, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accessToken':localStorage.getItem('access_token'),
                },
            });

            if (response.status === 401) {
                window.location.href = "/login";                    
            }

            if (response.ok) {
                const json = await response.json();
                setResultVariant('success');
                setResultVisible(false);
                setResultText('');
                setData(json);
            } else {
                setResultVariant('warning');
                setResultVisible(true);
                setResultText('Ett fel uppstod!!');

                throw response;
            }
        }
        catch (e) {
            setResultVariant('warning');
            setResultVisible(true);
            setResultText('Ett fel uppstod!!!');

        }
        finally {
            setLoadning(false);
        }
    }

    //Refresh();
    useEffect(() => {init()}, [url]);  

    return { resultVariant, resultVisible, resultText, loading, data, Refresh, Seek }; 
}