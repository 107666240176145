import '../App.css';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import React, { useState, Component } from 'react';
import {  useNavigate } from 'react-router';

const Prices = () => {
    let navigate = useNavigate();

    function handleSubmitProd1(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=L1'); 
    }    

    function handleSubmitProd2(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=L2'); 
    }    
    
    function handleSubmitProd3(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=L3'); 
    }    
    
    function handleSubmitProd4(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=L4'); 
    }        

    return (
    <>
        <PricingTable highlightColor='#46A969'>


        <PricingSlot onClick={handleSubmitProd1}   buttonText='SIGN UP' title='SingleLock' priceText='199:-/månad'>
                <PricingDetail> <b>Bindningstid</b> 0 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 990:-</PricingDetail>
                <PricingDetail> <b>Antal nycklar</b> Obegränsat</PricingDetail> 
                <PricingDetail> <b>Antal lås</b> 1<br /><small>Önskas flera låspunkter välj Multilock</small></PricingDetail>
                <PricingDetail> <b>Extra låsmodul</b> N/A</PricingDetail>

            </PricingSlot>

            <PricingSlot onClick={handleSubmitProd2}  highlighted buttonText='SIGN UP' title='SingleLock' priceText='199:-/månad'>
                <PricingDetail> <b>Bindningstid</b> 12 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 0:-</PricingDetail>
                <PricingDetail> <b>Antal nycklar</b> Obegränsat</PricingDetail>                 
                <PricingDetail> <b>Antal lås</b> 1<br /><small>Önskas flera låspunkter välj Multilock</small></PricingDetail>
                <PricingDetail> <b>Extra låsmodul</b> N/A</PricingDetail>
            </PricingSlot>            

            <PricingSlot  onClick={handleSubmitProd3}  buttonText='SIGN UP' title='MultiLock' priceText='299:-/månad'>
                <PricingDetail> <b>Bindningstid</b> 0 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 990:-</PricingDetail>
                <PricingDetail> <b>Antal nycklar</b> Obegränsat</PricingDetail>                 
                <PricingDetail> <b>Antal lås</b> 30:-/låspunkt/månad<br /><small>1 låsåunkt ingår i månadsavgiften</small></PricingDetail>
                <PricingDetail> <b>Extra låsmodul</b> 990:-<br /><small>1 modul ingår</small></PricingDetail>
            </PricingSlot>

            <PricingSlot onClick={handleSubmitProd4}   buttonText='SIGN UP' title='MultiLock' priceText='299:-/månad' >
                <PricingDetail> <b>Bindningstid</b> 12 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 0:-</PricingDetail>
                <PricingDetail> <b>Antal nycklar</b> Obegränsat</PricingDetail>                 
                <PricingDetail> <b>Antal lås</b> 30:-/låspunkt/månad<br /><small>1 låsåunkt ingår i månadsavgiften</small></PricingDetail>
                <PricingDetail> <b>Extra låsmodul</b> 990:-<br /><small>1 modul ingår</small></PricingDetail>
            </PricingSlot>

        </PricingTable>
    </>
  )
};

export default Prices;        