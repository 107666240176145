import { Container,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,Stycke,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";

import React from 'react';

const styles = {
    arrowColor: "#46A969",
    rowContentColor: '#333',
    titleTextColor: '#f00',
    bgColor: '#eee',
    titleTextSize: '48px'
  };
  
  const config = {
     animate: true,
  };

  export default class Operate extends React.Component  {

    constructor(props) {
      super(props);
      this.state = {
        data: null,
        isLoading: false,
        error: null,
        resultVariant :"success",
        resultVisible:false,
        resultText :'N/A',
        spinnerHidden:false,
        keyName:"",
        KeyEmail:"",
        keyTypeTx:"",
        keyTimeFrame:"",
      }
    }

    // componentDidMount() {
    //   this.populateKeyInfo();   
    //   this.setState({});
    // }

    // populateKeyInfo= () => {

    //   var url = "/api/v1"+window.location.pathname.replace('unlock','info');
    
    //   const that = this;
    //   fetch(url)
    //   .then((response) => response.json())
    //   .then((findresponse)=>{
    //     this.state.keyName=findresponse.name;
    //     this.state.KeyEmail=findresponse.KeyEmail;
    //     this.state.keyTypeTx=findresponse.KeyTypeTx;
    //     this.state.keyTimeFrame=findresponse.TimeFrame;
    //     this.state.spinnerHidden=true;

    //     this.setState({});
    //   })        
    // }

    render(){
      if (this.state.keyName == "") {
        return (          
          <>
          <ContentWrapper>
          <Container>
          <Sida style={{minHeight:Pageheight(400)}}>
          <Rubrik>Hämtar nycklar</Rubrik>
                  <StyckeCompact>
                        • Här ska personliga nycklar visas<br></br>
                        • Här ska man kunna exekvera personliga nycklar<br></br>
                        • Här ska man kunna revokera/återlämna egna nycklar<br></br>
                    </StyckeCompact>            
                  <Form.Group className="mb-3" >
                      <SpinnerX  />
                  </Form.Group>                                      
          </Sida>
          </Container>
          </ContentWrapper>
          </>
        );
      }
      else {
        return(
          <>
          <ContentWrapper>
          <Container>
          <Sida style={{minHeight:Pageheight(400)}}>
          <Rubrik>Nycklar</Rubrik>
				 
          </Sida>
          </Container>
          </ContentWrapper>

          </>
        );
      };
    }
}