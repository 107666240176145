import React, {useState} from "react";
import { Outlet, Link } from "react-router-dom";
import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form} from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import './Top.css';
import { Parallax, Background } from 'react-parallax';

import Menu from "./Menu";

const history = createBrowserHistory();

const Top = () => {
	return (
	<>
	<div className="Top">
		<div className="TopWrapper_" id="xxx">
			<Container>
				<p className="TopLine" id="logo">Digitala nycklar</p>
			</Container>
		</div>
	</div>
	</>
	)
};

export default Top;