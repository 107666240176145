import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React, { useState ,useEffect} from 'react';
import useBackend from '../services/useBackend';
import BackendFunction from '../services/BackendFunction';
import {useParams, useNavigate} from 'react-router-dom';

export default function MyComponent() {

    // const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    
    const [loading, setLoadning] = useState(false);

    const navigate = useNavigate();   

    const {LockGUID} = useParams();    

    const {loading:loadinit, data:LockData} =  useBackend('/api/v1/Lock/'+ LockGUID + '' );  

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );  

    const handleSaveClick = async (e) =>{
        e.preventDefault();
        setLoadning(true);

        setResultText("Sparar inställningar.....");
        setResultVariant("warning");
        setResultVisible(true);
                
        var data = { LockGuid : LockGUID };
        data.LockName = e.target.elements.LockName.value;
        data.LockDescription = e.target.elements.LockDescription.value;

        console.log(data);

        const {resultVariant, resultVisible, resultText} = await  BackendFunction('/api/v1/Lock/'+ LockGUID,'PATCH', data );  
        
        await delay(1000);

        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(true);

        setLoadning(false);        
    }     
    
    const handleDeleteClick = async (e) =>{
        e.preventDefault();

        if (window.confirm('Vill du radera låset?')) {
                var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Lock/'+LockGUID,'DELETE');
                setResultVariant(resultVariant);   
                setResultText(resultText);
                setResultVisible(resultVisible);
                //window.location.reload();
        }
    }        

    const handleLinkClick = (event)  =>  {
        const link = event.currentTarget.getAttribute("link");
        navigate("/Locks/"+LockGUID+"/"+link);
    }    

    if (!loadinit) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Inställningar</Rubrik>

                    <Form onSubmit={handleSaveClick}>
                        <Form.Group className="mb-3">
                            <Form.Label>Namn</Form.Label>
                            <Form.Control type="text" name ="LockName" placeholder="Ange namn på låset" defaultValue={LockData.LockInfo.Name}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Beskrivning</Form.Label>
                            <Form.Control type="text" name ="LockDescription" placeholder="Beskrivning" defaultValue={LockData.LockInfo.Description}/>
                        </Form.Group>
                      
                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>    

                        <Form.Group className="mb-3">
                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" >Spara</Button>
                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleDeleteClick} link="DeleteLock">Radera låset</Button>
                        </Form.Group>                                                                              
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar inställningar</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}