import ClipLoader from "react-spinners/PacmanLoader";
import React, { useState, Component } from 'react';
import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Pageheight} from "../index";
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import { FaFilePdf, FaBars, FaRegCheckCircle } from 'react-icons/fa';

import '../App.css';

export function Check(props) {
  if (props.checked) {
    return <FaRegCheckCircle />;
  } else {
    return "";
  }
}

export function Loading() {

    let [loadingText, setLoadingText] = useState("Laddar");
    let [color, setColor] = useState("#FFC90E");

    return (
      <ContentWrapper>
          <Container>
              <Sida style={{minHeight:Pageheight(400)}}>
              <Rubrik>{loadingText}</Rubrik>
              <div className="Spinner">
                <ClipLoader color={color}  size={30} />
              </div>
          </Sida>
          </Container>
      </ContentWrapper>
    );  
  };