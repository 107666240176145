import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React, { useState ,useEffect} from 'react';
import useBackend from '../services/useBackend';
import BackendFunction from '../services/BackendFunction';
import {useParams, useNavigate} from 'react-router-dom';
import LockInfo from './LockInfo';

export default function MyComponent() {

    // const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    const {LockGUID} = useParams();    

    const {loading, data:History} =  useBackend('/api/v1/Lock/'+ LockGUID + '/History' );  

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Låshistorik</Rubrik>
                    <LockInfo LockGUID={LockGUID}></LockInfo>
                    <StyckeCompact>
                        <Table  responsive="sm">
                            <thead>
                                <tr>    <td>Tid</td> <td className="d-lg-block d-none">Description</td> <td>Användare</td> <td></td>  </tr>
                            </thead>
                            <tbody>
                                {History.LogEntrys.map((item) => {
                                    return <tr> <td>{item.Created}</td> <td>{item.Description}</td>  <td>{item.UserId}</td> </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>                   
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar historik</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}