import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React from 'react';
import useBackend from '../services/useBackend';
import {FaBars} from 'react-icons/fa';
import { generatePath, useNavigate } from 'react-router';

export default function MyComponent() {
    const {resultVariant, resultVisible, resultText, loading, data} =  useBackend('/api/v1/Lock');

    const navigate = useNavigate();

    const operateLockClick = (event)  =>  {
        const LockGUID = event.currentTarget.getAttribute("LockGUID");
        navigate("/Locks/"+LockGUID);
    }

    const claimDeviceHandler = (event)  =>  {
        event.preventDefault();
        navigate("/claimDevice");
    }        

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Lås</Rubrik>
                    <StyckeCompact>
                        <Table  responsive="sm">
                            <thead>
                                <tr>    <td>Namn</td> <td className="d-lg-block d-none">Description</td> <td>Status</td> <td></td>  </tr>
                            </thead>
                            <tbody>
                                {data.Locks.map((item) => {
                                    return <tr> <td>{item.Name}</td> <td>{item.Description}</td>  <td>{item.StateText}</td>
                                        <td >
                                            <span style={{padding:5}} className='sub_' LockGUID={item.Guid} onClick={operateLockClick} title="Info "><FaBars /></span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>  

                    <Form>
                        <Form.Group className="mb-3">
                            <Button className="QmeButton" variant="primary" type="submit" onClick={claimDeviceHandler}>Anslut nytt lås</Button>
                        </Form.Group>                                
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Loading Locks</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}