import '../../App.css';
import React, { useState, useEffect } from "react";

import { Container, Alert, Form} from 'react-bootstrap';

import {Sida,Rubrik,ContentWrapper} from "../ContentStyles";

import SpinnerX from '../SpinnerX';

const Info = () => {
  const [InstanceVerifyGUID, setInstanceVerifyGUID] = useState(window.location.pathname.replace('/Instance/Verify/',''));

  const [resultText, setResultText] = useState(":)");
  const [resultVariant, setResultVariant] = useState("success");
  const [resultVisible, setResultVisible] = useState(false);
  const [spinnerHidden, setSpinnerHidden] = useState(false);

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: (window.innerHeight-600)
  });
  
  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: (window.innerHeight-600)
    });
  }
  useEffect(() => (window.onresize = updateSize), []);  
  useEffect(() => (window.onscroll = updateSize), []);  

  function handleSubmit(e) {
    e.preventDefault();

    setSpinnerHidden(false);
    setResultVisible(true);

    var PostData = {SystemID:"L", VerifyGUID : InstanceVerifyGUID};  

    fetch('/backend/instance/Verify', {
    method: 'POST',
    headers: {
       'Content-Type': 'application/json'
    },
    body: JSON.stringify(PostData),
 }).then(response => response.json())
 .then(data => {
    if (data.errorsException===false) {
        setResultVariant("success");      
        setResultText(data.resultText);        
        setSpinnerHidden(true);
        setResultVisible(true);
    } else {
        setResultVariant("warning");
        setResultText(data.resultText);
        setSpinnerHidden(true);
        setResultVisible(true);
    }
 })
 .catch(error => {
    setResultVariant("warning");
    setResultText("Ett fel uppstod!" + JSON.stringify(PostData));
    setSpinnerHidden(true);
    setResultVisible(true);
  });
}  

useEffect(() => (window.onload = handleSubmit), []);  
  return (
    <ContentWrapper>
    <Container>
    <Sida style={{minHeight:size.y}}>

        <Rubrik>Verifiera konto</Rubrik>

        <Form.Group className="mb-3"  hidden={spinnerHidden}>
          <SpinnerX  />
        </Form.Group>                                      

        <Form.Group className="mb-3"  hidden={!resultVisible}>
          <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
        </Form.Group>          

    </Sida>
    </Container>
    </ContentWrapper>
  );
};

export default Info;