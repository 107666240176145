import ClipLoader from "react-spinners/PacmanLoader";
import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React, { useState, Component } from 'react';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import useBackend from '../services/useBackend';

import '../App.css';

export default function LockInfo(props) {

  const {loading, data:LockData} =  useBackend('/api/v1/Lock/'+ props.LockGUID );  

  if (!loading) {
    return (
      <StyckeCompact>
      <Table>
      <tbody>
        <tr><td>Namn</td><td><large><b>{LockData.LockInfo.Name}</b></large></td></tr>
        <tr><td>Beskrivning</td><td>{LockData.LockInfo.Description}</td></tr>
        <tr><td>Låstyp</td><td>{LockData.LockInfo.TypeId}</td></tr>
        <tr><td>Skapat</td><td>{LockData.LockInfo.Created}</td></tr>
      </tbody>
      </Table>


      </StyckeCompact>  
    )
  } else {
    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Laddar info..</Rubrik>
                <SpinnerX  />
            </Sida>
            </Container>
        </ContentWrapper>
    );        
  }

}


  
