import React, { useState ,useEffect} from 'react';

export default async function BackendFunction(url, method, body = {}) {

    var resultVariant = "warning";
    var resultText =  "From backend";
    var resultVisible = true;

    async function postData(url = '',method = 'GET', data = {}) {
        try {
            resultText =  "From backend!!!"

            // Default options are marked with *
            const response = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url

            body: JSON.stringify(body)

            // body: JSON.stringify(data) // body data type must match "Content-Type" header
            });

            if (response.ok) {
                const json = await response.json();
                resultVariant = "success";
                if (json.errorsException) resultVariant = "warning";
                resultVisible = true;
                resultText = json.resultText;                
            }
        }
        catch (e) {
            resultVariant = "warning";
            resultVisible = true;
            resultText = "Ett fel uppstod!!!";                     
        }
        finally {
            // setLoadning(false);
        }
    }

    await postData(url, method, body);

    return { resultVariant, resultVisible, resultText}; 
}