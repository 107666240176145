import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React, { useState ,useEffect} from 'react';
import useBackend from '../services/useBackend';
import BackendFunction from '../services/BackendFunction';

export default function MyComponent() {

    // const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    //const {loading, data} =  useBackend('/api/v1/Lock/'+ window.location.pathname.replace('/Locks/','') );  
    const loading = false;

    const handleUnlockClick = async (e) =>{
        e.preventDefault();

        var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Operate/wcgKMFik5bh2XFend9fDjymrHDNBRtGVgy0YZIv7IZbQtMgwgjUNTpL9ZYci3RCL/unlock','GET');
        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(resultVisible);
    }  

    const noFunctionClick = async (e) =>{
        e.preventDefault();

        // var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Operate/wcgKMFik5bh2XFend9fDjymrHDNBRtGVgy0YZIv7IZbQtMgwgjUNTpL9ZYci3RCL/unlock','GET');
        setResultVariant('warning');   
        setResultText('N/A');
        setResultVisible(true);
    }        

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Låsgrupper</Rubrik>
                    <StyckeCompact>
                        • Låsgrupper används för att kunna dela en grupp av lås med en nyckel, tex alla lås på en viss anläggning.<br></br>
                        • Admin och superadmin ska kunna skapa, redigera och radera låsgrupper.<br></br>
                        <img src="/images/underconstruction.png"></img>
                    </StyckeCompact>                      
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar låsgrupper</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}