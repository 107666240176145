import { Container, Button, Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import {useParams, useNavigate} from 'react-router-dom';

import { useState, useEffect, useRef } from 'react';

import useBackend from '../services/useBackend';
import BackendFunction from '../services/BackendFunction';

const styles = {
    arrowColor: "#46A969",
    rowContentColor: '#333',
    titleTextColor: '#f00',
    bgColor: '#eee',
    titleTextSize: '48px'
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );  

  function DateTimeToLocal(isoDate)  {
      var d = new Date(isoDate);
      return d.toLocaleDateString() + " " + d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  }
  
  export default function MyComponent() {

    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    const {Key} = useParams();    

    const {loading, data, Refresh} =  useBackend('/api/v1/operate/'+ Key  );  

    const handleUnlockClick = async (e) =>{
      e.preventDefault();
      setResultText("Öppnar.....");
      setResultVariant("warning");
      setResultVisible(true);
      
      var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Operate/'+Key+'/unlock','POST');

      await delay(1000);

      setResultVariant(resultVariant);

      setResultText(resultText);
      setResultVisible(resultVisible);
      Refresh();
    }      

    if (!loading) {
        return (
          <>
          <ContentWrapper>
          <Container>
          <Sida style={{minHeight:Pageheight(400)}}>
          <Rubrik>Nyckelinformation</Rubrik>
              <Form onSubmit={handleUnlockClick}>
              <StyckeCompact>
                <table>
                  <tr> <td>Lås</td> <td>{data.Lock.Name}</td> </tr>
                  <tr> <td>Nyckeltyp</td> <td>{data.Key.KeyType.Name}</td> </tr>
                  <tr> <td>Giltlig från</td> <td>{DateTimeToLocal(data.Key.TimeFrameStart)}</td> </tr>
                  <tr> <td>Giltlig till</td> <td>{DateTimeToLocal(data.Key.TimeFrameEnd)}</td> </tr>
                  <tr> <td>Kvarvarande klipp&nbsp;&nbsp;&nbsp;</td> <td>{data.Key.NumOfPunches}</td> </tr>
                  <tr> <td>Låsstatus</td> <td>{data.Lock.Device.Online? 'Online' : 'Offline'}</td> </tr>              
                </table>
                </StyckeCompact>

                  <Form.Group className="mb-3"  hidden={!loading}>
                      <SpinnerX  />
                  </Form.Group>                                      

                  <Form.Group className="mb-3"  hidden={!resultVisible}>
                      <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                  </Form.Group>        

                  <Form.Group className="mb-3">
                      <Button className="QmeButton" variant="primary" type="submit">Lås upp</Button>
                  </Form.Group>  
              </Form> 					 
          </Sida>
          </Container>
          </ContentWrapper>
          </>
        );
    } else {
        return (
          <>
          <ContentWrapper>
          <Container>
          <Sida style={{minHeight:Pageheight(400)}}>
          <Rubrik>Hämtar nyckel</Rubrik>
                  <Form.Group className="mb-3" >
                      <SpinnerX  />
                  </Form.Group>                                      
          </Sida>
          </Container>
          </ContentWrapper>
          </>
        );        
    }
}