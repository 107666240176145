import { Container, Alert,Table, Form} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React, { useState} from 'react';
import useBackend from '../services/useBackend';
import BackendFunction from '../services/BackendFunction';
import {useParams, useNavigate} from 'react-router-dom';
import LockInfo from './LockInfo';
import {Loading, Check} from './Loading';

import {FaLockOpen, FaTrashAlt, FaRegEnvelope, FaHistory}  from 'react-icons/fa';

export default function MyComponent() {

    // const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    const navigate = useNavigate();    

    const {LockGUID} = useParams();    

    //const {data:LockData} =  useBackend('/api/v1/Lock/'+ LockGUID,'GET' );  

    const {loading, data} =  useBackend('/api/v1/Lock/'+ LockGUID + '/KeyList' );  

    const RevokeKeyClick = async (e) =>{
        e.preventDefault();


        if (window.confirm('Vill du återkalla nyckeln?')) {
            var Token = e.currentTarget.getAttribute("Token");

                var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/key/revoke','PUT', {Token: Token});
                setResultVariant(resultVariant);   
                setResultText(resultText);
                setResultVisible(resultVisible);
                window.location.reload();
        }
    }  

    const ResendKeyClick = async (e) =>{
        e.preventDefault();

        if (window.confirm('Vill du skicka om nyckeln?')) {
            var Token = e.currentTarget.getAttribute("Token");
            var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/key/ResendKey','PUT', {Token: Token});            
            setResultVariant(resultVariant);   
            setResultText(resultText);
            setResultVisible(resultVisible);     
        }
    }      

    const noFunctionClick = async (e) =>{
        e.preventDefault();

        // var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Operate/wcgKMFik5bh2XFend9fDjymrHDNBRtGVgy0YZIv7IZbQtMgwgjUNTpL9ZYci3RCL/unlock','GET');
        setResultVariant('warning');   
        setResultText('noFunctionClick');
        setResultVisible(true);
    }        

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>



                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Nyckellista</Rubrik>
                    <LockInfo LockGUID={LockGUID}></LockInfo>
                    <StyckeCompact>
                        <Table  responsive="sm">
                            <thead>
                                <tr>    <td>Skapad</td> <td className="d-lg-block d-none">Epost</td> <td>BankID</td> <td>Giltig tom</td> <td></td>  </tr>
                            </thead>
                            <tbody>
                                {data.KeyList.map((item) => {
                                    return <tr> <td>{item.Created}</td> <td>{item.eMail}</td> <td><Check checked={item.BankID}/></td>  <td>{item.TimeFrameEnd}</td>

                                        <td >
                                            <span style={{padding:5}} className='sub_' onClick={RevokeKeyClick} title="Revokera nyckel" Token={item.Token}><FaTrashAlt /></span>
                                            <span style={{padding:5}} className='sub_' onClick={ResendKeyClick} title="Skicka om nyckel"  Token={item.Token}><FaRegEnvelope /></span>
                                            <span style={{padding:5}} className='sub_' onClick={noFunctionClick} title="Visa historik" ><FaHistory /></span>
                                        </td>

                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </StyckeCompact>      

                    <Form>

                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      

                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>        
                    </Form> 		          
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar nyckellista</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}