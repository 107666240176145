import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import LockInfo from './LockInfo';
import {Pageheight} from "../index";
import React, { useState ,useEffect} from 'react';
import useBackend from '../services/useBackend';
import BackendFunction from '../services/BackendFunction';
import {useParams, useNavigate} from 'react-router-dom';

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );  

export default function MyComponent() {

    const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    const navigate = useNavigate();    

    const {LockGUID} = useParams();

    const handleUnlockClick = async (e) =>{
        e.preventDefault();

        setResultText("Öppnar.....");
        setResultVariant("warning");
        setResultVisible(true);        

        var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Lock/'+LockGUID+'/unlock','POST');

        await delay(1000);
        
        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(resultVisible);
    }  

    const noFunctionClick = async (e) =>{
        e.preventDefault();

        setResultVariant('warning');   
        setResultText('N/A');
        setResultVisible(true);
    }    
    
    const handleLinkClick = (event)  =>  {
        const link = event.currentTarget.getAttribute("link");
        navigate("/Locks/"+LockGUID+"/"+link);
    }    

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Låsinfo</Rubrik>
                    <LockInfo LockGUID={LockGUID}></LockInfo>

                    <Form onSubmit={handleUnlockClick}>
                        <Form.Group className="mb-3"  hidden={!loading}>
                            <SpinnerX  />
                        </Form.Group>                                      
                            
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group>    

                        <Form.Group className="mb-3">
                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleUnlockClick}>Lås upp</Button>

                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="KeyIssue">Dela nyckel</Button>

                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="Keys">Visa nycklar</Button>

                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="Settings">Inställningar</Button>

                            <Button  style={{margin:5}} className="QmeButton" variant="primary" type="submit" onClick={handleLinkClick} link="History">Historik</Button>
                        </Form.Group>                                                                              
                    </Form>                 
                </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Loading Locks</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}