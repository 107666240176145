import '../App.css';

import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form} from 'react-bootstrap';

import $ from "jquery";
import Faq from "./Faq";
import React, { useState, Component } from 'react';
import axios from "axios";
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";
import Prices from "./Prices";
import CountDown from "./CountDown";


const shoot = () => {
  alert();
}

const Home = () => {

   return(
        <ContentWrapper>
    
        <Container>
        <Sida>
          <Rubrik>Säkra din verksamhet med digitala nycklar</Rubrik>
          <Stycke>
            Mer info kommer snart!.<br />
          </Stycke>

          <Rubrik>Exempel på tillämplingar</Rubrik>
          <Stycke>
            <ul>
              <li>Grindöppning</li>
              <li>Tillgång till lokaler</li>
              <li>Aktivering av eluttag</li>
              <li>Obemannad butiksverksamhet</li>
              <li>Obemannad uthyrning</li>
              <li>Solarium</li>              
            </ul>
          </Stycke>          
    
          <Rubrik>Abonnemang</Rubrik>
  
          <Prices />
          <Faq />
          
        </Sida>    
        </Container>

        </ContentWrapper>
      );
   }

export default Home;