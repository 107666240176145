import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import SpinnerX from './SpinnerX';
import {Pageheight} from "../index";
import React, { useState } from 'react';
import BackendFunction from '../services/BackendFunction';

export default  function MyComponent() {
    
    const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );  

    const handleClaimSubmit = async (e) =>{
        e.preventDefault();

        setLoadning(true);

        setResultText("Söker efter lås...");
        setResultVariant("warning");
        setResultVisible(true);

        var DeviceId = e.target.elements.DeviceId.value;


        var data = {  };
        data.LockName = e.target.elements.LockName.value;
        data.DeviceId = e.target.elements.DeviceId.value;

        var {resultVariant, resultVisible, resultText} = await BackendFunction('/api/v1/Devices/Claim/','POST',data);

        await delay(1000);
        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(resultVisible);
        setLoadning(false);
    }     

    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Anslut nytt lås</Rubrik>
                <StyckeCompact>
                    Registreringskoden för låset hittar du i förpackningen till låsmodulen.
                </StyckeCompact>  

                <Form onSubmit={handleClaimSubmit}>

                    <Form.Group className="mb-3" >
                            <Form.Label>Namn</Form.Label>
                            <Form.Control type="text" name ="LockName" placeholder="Ange namn på låset" />                            
                    </Form.Group>

                    
                    
                    <Form.Group className="mb-3" >
                            <Form.Label>Registreringskod</Form.Label>
                            <Form.Control type="text" name="DeviceId" placeholder="Finns på låsmodulen" />
                    </Form.Group>

                    <Form.Group className="mb-3"  hidden={!loading}>
                        <SpinnerX  />
                    </Form.Group>                                      
                        
                    <Form.Group className="mb-3"  hidden={!resultVisible}>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                    </Form.Group>                        

                    <Form.Group className="mb-3">

                        <Button className="QmeButton" variant="primary" type="submit">Anslut</Button>
                    </Form.Group>                                
                </Form>                 
            </Sida>
            </Container>
        </ContentWrapper>
    );

}